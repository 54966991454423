<template>
  <div>
    <!--  面包屑  -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>商品管理</el-breadcrumb-item>
      <el-breadcrumb-item>商品列表</el-breadcrumb-item>
    </el-breadcrumb>
    <!--  卡片视图区  -->
    <el-card class="box-card">
      <el-row :gutter="30">
        <el-col :span="4">
          <el-button @click="upxls" type="primary" size="mini">上传</el-button>
          <el-button @click="exportExcel" size="small" type="primary">下载模板</el-button>
        </el-col>
      </el-row>
      <el-table :data="goodslogList" border stripe v-loading="loading">
        <el-table-column type="index" label="编号"></el-table-column>
        <el-table-column label="添加时间" prop="add_date"></el-table-column>
        <el-table-column label="添加人" prop="u_idname"></el-table-column>
        <el-table-column label="文件名称" prop="file_name"></el-table-column>
        <el-table-column label="状态" prop="status_name"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" v-if="scope.row.status!==1" @click="downloadExcel(scope.row.id)">下载</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区 -->
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="querInfo.page"
          :page-sizes="[3,5,10,15]"
          :page-size="querInfo.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </el-card>
  </div>
</template>
<script>
  import params from "@/components/goods/params";
  import XLSX from "xlsx";

  export default {
    data(){
      return{
        //分页
        querInfo: {
          page: 1,
          limit: 10,
          g_name:''
        },
        total:0,
        loading:false

      }
    },
    created() {
      this.getGoodsLogList()
    },
    methods:{
      async getGoodsLogList(){
        this.loading=true
        const  {data:res} = await this.$http.get('goodslog/getList',{params:this.querInfo})
        if (res.code!=200)this.$message.error(res.msg)
        console.log(res)
        this.goodslogList=res.data.data
        this.total = res.data.total
        this.loading=false
      },
      //监听pagesize改变
      handleSizeChange(newSize) {
        this.querInfo.limit = newSize
        this.getGoodsLogList()
      },
      //监听pagenum 改变
      handleCurrentChange(newPage) {
        this.querInfo.page = newPage
        this.getGoodsLogList ()
      },
      //上传excel
      upxls(){
        this.$router.push('/excel')
      },
      //下载
      downloadExcel(id){
        console.log(id)
        return new Promise((resolve, reject) => {
          // console.log(resolve)
          // console.log(reject)
          const {data:res} = this.$http.get('goodslog/export', {
            params:{id:id},
            responseType: "blob" // 1.首先设置responseType对象格式为 blob:
          })
            .then(
              res => {
                //resolve(res)
                let blob = new Blob([res.data], {
                  type: "application/vnd.ms-excel"
                }); // 2.获取请求返回的response对象中的blob 设置文件类型，这里以excel为例
                let url = window.URL.createObjectURL(blob); // 3.创建一个临时的url指向blob对象
                // 4.创建url之后可以模拟对此文件对象的一系列操作，例如：预览、下载
                let a = document.createElement("a");
                var date = new Date();
                a.href = url;
                a.download = "商品"+date.getFullYear()+"-"+ (date.getMonth()+1) +"-"+date.getDate() +".xlsx";
                a.click();
                // 5.释放这个临时的对象url
                window.URL.revokeObjectURL(url);
              },
              err => {
                resolve(err.response);
              }
            )
            .catch(error => {
              reject(error);
            });
        });

      },
      //下载模板
      async exportExcel(){
        const  {data:res} = await this.$http.get('goods/exportTemplate')
        if (res.code!=200)return this.$message.error(res.msg)
        let ws = XLSX.utils.aoa_to_sheet(res.data.data)
        let wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, res.data.name) // 工作簿名称
        XLSX.writeFile(wb, res.data.name) // 保存的文件名
        // this.$message.success(res.msg)
      },
    }

  }
</script>
